import React, { forwardRef } from 'react'
import cx from 'classnames'

import { ButtonBase } from 'components/inputs'
import type { ButtonBaseProps } from 'components/inputs'

import s from './ButtonStyle.scss'


export const sizes = [ 56, 48, 40, 24 ] as const

export const styles = [ 'primary', 'secondary', 'accent' ] as const

type Size = typeof sizes[number]

type Style = typeof styles[number]

export type ButtonStyleProps = Omit<ButtonBaseProps, 'style'> & {
  // basic
  afterNode?: React.ReactNode

  // modifiers
  size: Size
  style?: Style
  fullWidth?: boolean
}

const ButtonStyle = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonStyleProps>((props, ref) => {
  const {
    // basic
    children, className, afterNode,

    size, style = 'primary',
    fullWidth, disabled, loading,

    ...rest
  } = props

  // Classes ----------------------- /

  const nodeClassName = cx(s.button, className, s[`size-${size}`], s[style], {
    'w-full': fullWidth,
    'items-start': !fullWidth,
    [s.disabled]: disabled,
    [s.loading]: loading,
  })

  const nodeProps = {
    className: nodeClassName,
    disabled,
    loading,
    ...rest,
  }

  return (
    <ButtonBase ref={ref} {...nodeProps}>
      {children}
      {afterNode}
    </ButtonBase>
  )
})


export default ButtonStyle
