import cx from 'classnames'
import { Field, useFieldState } from 'formular'
import React, { useCallback, useMemo, useRef } from 'react'

import { getImage } from 'models/mapLayer'

import { Avatar, AvatarProps } from 'components/dataDisplay'
import UploadPhotoModal, { openUploadPhotoModal } from 'compositions/modals/UploadPhotoModal/UploadPhotoModal'

import { DropdownMenuProps } from 'components/navigation'
import { openSubmitFailedModal } from 'compositions/modals'
import addPhotoPlaceholderSquare from 'components/dataDisplay/Avatar/images/AddPhotoPlaceholderSquare.svg'
import addPhotoPlaceholderRound from 'components/dataDisplay/Avatar/images/AddPhotoPlaceholderRound.svg'


type AvatarInputProps = {
  className?: string
  shape?: AvatarProps['shape']
  imageField: Field<string>
  disabled?: boolean
  aspectRatio?: number
  circularCrop?: boolean
  uploadUrl?: string
  handleImageDelete?: () => void
}

const AvatarInput: React.FC<AvatarInputProps> = (props) => {
  const { className, shape = 'round', imageField, disabled, aspectRatio, circularCrop = true, uploadUrl,
    handleImageDelete } = props

  const { value: imageUrl } = useFieldState<string>(imageField)

  const image = useMemo(() => {
    return imageUrl && imageUrl !== '' ? `${getImage(imageUrl)}&h=214` : null
  }, [ imageUrl ])

  const fileInputRef = useRef<HTMLInputElement>()

  const handleFileInputChange = useCallback((event) => {
    const files: FileList = event.target.files
    const file = files[0]
    const allowedMimes = [ 'image/jpeg', 'image/png' ]

    if (!file || !allowedMimes.includes(file.type)) {
      openSubmitFailedModal({
        title: 'Не удалось загрузить файл',
        text: 'Формат файла не поддерживается - разрешенные типы .jpg, .jpeg, .png',
        buttonTitle: 'Понятно',
      })
      fileInputRef.current.value = ''
      return
    }

    const reader = new FileReader()

    reader.addEventListener('load', () => openUploadPhotoModal({
      fileName: file.name,
      imageBlob: file,
      field: imageField,
      message: 'Скадрируйте снимок так, чтобы было хорошо видно объект',
      aspectRatio,
      circularCrop,
      uploadUrl,
    }))

    reader.readAsDataURL(file)
  }, [ imageField, aspectRatio, circularCrop, uploadUrl ])

  const handleUploadClick = useCallback(() => {
    fileInputRef.current.click()
  }, [])

  const menuItems: DropdownMenuProps['items'] = useMemo(() => ([
    // {
    //   title: 'Кадрировать',
    //   icon: 'main/edit_16',
    //   iconColor: 'rocky',
    //   onClick: () => handleUploadClick(),
    // },
    {
      title: 'Изменить',
      icon: 'action/update_16',
      iconColor: 'rocky',
      onClick: () => handleUploadClick(),
    },
    ...(handleImageDelete ? [ {
      title: 'Удалить',
      icon: 'main/delete_16',
      iconColor: 'fargo',
      onClick: () => handleImageDelete(),
    } ] as DropdownMenuProps['items'] : []),
  ]), [ handleImageDelete, handleUploadClick ])

  return (
    <div className={className}>
      {
        image ? (
          <Avatar
            size={104}
            image={image}
            // originalImage={photoState.value}
            editable={!disabled}
            style="dark"
            shape={shape}
            editMenuItems={menuItems}
            withFullscreen
            // onEditClick={() => handleUploadClick()}
          />
        ) : (
          <img
            className={cx({ 'pointer': !disabled, 'opacity-16': disabled })}
            src={shape === 'round' ? addPhotoPlaceholderRound : addPhotoPlaceholderSquare}
            onClick={() => handleUploadClick()}
          />
        )
      }
      <input
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileInputChange}
        type="file"
        accept=".png, .jpeg, .jpg"
        disabled={disabled}
      />
    </div>
  )
}

export default AvatarInput