import React, { forwardRef } from 'react'
import cx from 'classnames'

import { ButtonBase, ButtonBaseProps } from 'components/inputs'
import { Icon, IconName, Text } from 'components/dataDisplay'

import s from './ActionButton.scss'


export const sizes = [ 'c16', 'c13' ] as const
export const colors = [ 'titanic', 'rocky', 'fargo', 'mulholland', 'platoon', 'lebowski' ] as const
export const arrows = [ 'up', 'down', 'right' ] as const

export type ActionButtonProps = ButtonBaseProps & {
  className?: string
  iconClassName?: string
  title?: string
  icon?: IconName // TODO make only 16 available
  color: typeof colors[number]
  size?: typeof sizes[number]
  arrow?: typeof arrows[number]
  arrowSize?: 8 | 16
  disabled?: boolean
  textColor?: typeof colors[number]
  textRef?: React.MutableRefObject<HTMLDivElement>
}

const ActionButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, ActionButtonProps>((props, ref) => {
  const { className, iconClassName, title, icon, color, size = 'c16', arrow, arrowSize = 8, disabled, textColor = 'inherit', textRef, ...rest } = props

  const arrowIconName = `arrow/arrow-${arrow}_${arrowSize}`

  return (
    <ButtonBase
      ref={ref}
      className={cx(s.actionButton, s[`color-${color}`], className, {
        [s[`size-${size}`]]: !title,
        [s.disabled]: disabled,
      })}
      disabled={disabled}
      {...rest}
    >
      {
        icon && (
          <Icon
            className={cx(iconClassName, {
              'mr-6px': title || arrow,
            })}
            name={icon}
            color="inherit"
          />
        )
      }
      {
        title && (
          <Text ref={textRef} className="text-left overflow-ellipsis whitespace-nowrap" size={size} message={title} color={textColor} />
        )
      }
      {
        arrow && (
          <Icon
            className={title ? 'ml-8px' : ''}
            name={arrowIconName as IconName}
            color="inherit"
          />
        )
      }
    </ButtonBase>
  )
})


export default React.memo(ActionButton)
