import React, { useEffect } from 'react'
import { useField } from 'formular'

import Input, { InputProps } from '../Input/Input'


export type InputViewProps = Omit<InputProps, 'field'> & {
  className?: string
  value: string
}

const InputView: React.FC<InputViewProps> = (props) => {
  const { className, value, ...rest } = props

  const field = useField<string>({
    value,
  })

  useEffect(() => {
    if (value !== field.state.value) {
      field.set(value)
    }
  }, [ value, field ])

  return (
    <Input
      className={className}
      field={field}
      {...rest}
    />
  )
}


export default React.memo(InputView)
